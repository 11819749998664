<template>
  <div>
    <div
      v-if="type === 'edit' && !editBusinessAccountPopulated"
      class="row justify-center items-center"
    >
      <q-spinner color="primary" size="3em" />
    </div>
    <div v-else>
      <q-form @submit="$emit('submit', advertiserAccount)">
        <q-card flat bordered>
          <q-card-section>
            <h5>Account Details</h5>
          </q-card-section>
          <q-card-section>
            <div class="q-gutter-y-md">
              <q-input
                filled
                v-model="advertiserAccount.name"
                label="Account Name"
                lazy-rules
                :rules="[
                  val => (val && val.length > 1) || 'Enter an Account Name.'
                ]"
                :disable="true"
              />
            </div>
          </q-card-section>
        </q-card>

        <q-card flat bordered class="q-mt-md">
          <q-card-section>
            <h5>Account Configurations</h5>
          </q-card-section>
          <q-card-section>
            <div class="q-gutter-y-md">
              <q-input
                filled
                v-model="advertiserAccount.bid_value_scrub_percentage"
                label="Payout Value Scrub Percentage"
                inputmode="numeric"
                pattern="[0-9]*"
                type="number"
                lazy-rules
                :rules="[
                  val =>
                    (val && val.length > 0 && val >= 0 && val <= 100) ||
                    'Enter a Payout Value Scrub Percentage.'
                ]"
                :disable="submitting"
              />
              <q-input
                v-show="
                  advertiserAccount.conversion_goal_event_type_id === '4' ||
                    advertiserAccount.conversion_goal_event_type_id === '7'
                "
                filled
                v-model="advertiserAccount.lead_payout_value"
                label="Lead Payout Value"
                mask="#.##"
                fill-mask="0"
                :disable="submitting"
              />
            </div>
          </q-card-section>
        </q-card>

        <FormActionsSection>
          <template v-slot:primary-actions>
            <q-btn
              :label="type === 'create' ? 'Create Account' : 'Update Account'"
              type="submit"
              color="primary"
              :disable="submitting"
              :loading="submitting"
            />
            <q-btn
              flat
              label="Cancel"
              to="/manage/network/advertiser/accounts"
              color="subtle"
              :disable="submitting"
            />
          </template>
        </FormActionsSection>
      </q-form>
    </div>
  </div>
</template>

<script>
import FormActionsSection from "@/components/UI/FormActionsSection";
import axios from "axios";
export default {
  name: "AdvertiserAccountForm",
  components: { FormActionsSection },
  props: {
    type: {
      type: String,
      required: true
    },
    advertiserAccountId: {
      type: String,
      required: false
    },
    submitting: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      advertiserAccount: {
        id: "0",
        name: "",
        business_id: "",
        bid_value_scrub_percentage: "",
        lead_payout_value: ""
      }
    };
  },
  computed: {
    editBusinessAccountPopulated() {
      return this.advertiserAccount.id !== "0";
    },
    selectedPayoutMethod() {
      return this.advertiserAccount.bid_method;
    }
  },
  mounted() {
    // Get our ad account
    axios({
      url:
        "https://api-v2.jumpfeed.com/internal/advertiser-accounts?id=" +
        this.$route.params.advertiserAccountId
    })
      .then(resp => {
        this.advertiserAccount = resp.data.data[0];
        if (this.advertiserAccount.bid_value_scrub_percentage.length === 0) {
          this.advertiserAccount.bid_value_scrub_percentage = "0";
        }
        this.loading = false;
      })
      .catch(err => {
        console.log(err);
        this.loading = false;
      });
  }
};
</script>

<style scoped lang="scss"></style>
